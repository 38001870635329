
import {
  defineAsyncComponent,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
} from "vue";
import { gpf } from "@/utils/global-functions";
import { useRouter } from "vue-router";
import { VueCookieNext as $ck } from "vue-cookie-next";
import { getRole } from "@/utils/cookie-next";
import { loadingScreen } from "@/utils/loading-screen";
import { setupRoles } from "@/utils/roles";
import { setupCurrentUserPackage } from "@/utils/current-user-package";

const DarkModeSwitcher = defineAsyncComponent(
  () => import("@/components/dark-mode-switcher/Main.vue")
);

const ModalLupaPassword = defineAsyncComponent(
  () => import("./components/modal-lupa-password.vue")
);

const servicesV1Auth = async () => await import(`@/services/v1/auth`);

export default defineComponent({
  components: {
    DarkModeSwitcher,
    ModalLupaPassword,
  },
  setup() {
    const router = useRouter();
    const isPasswordVisible = ref(false);
    const form = reactive({
      username: "",
      password: "",
    });

    // START --- do login -----------------------------------------------------------------------------------
    const doLogin = async () => {
      loadingScreen("Tunggu Sebentar, Sedang Login.").show();

      const { login } = await servicesV1Auth();

      try {
        const payload = new URLSearchParams();
        payload.append("username", form.username);
        payload.append("password", form.password);

        const hit = await login(payload);
        const resData = hit.response;
        // console.log("response success ", hit);

        $ck.setCookie("login-data", resData);
        $ck.setCookie("tkn-data", resData.token);
        $ck.setCookie("role-data", resData.role);

        setupRoles();
        setupCurrentUserPackage();
        getListMenu();
      } catch (e: any) {
        const err = e.response;
        console.log("err response ", err);

        loadingScreen().hide();
        gpf.handleEds(e);
      }
    };
    // END --- do login -------------------------------------------------------------------------------------

    // START --- get data menu ------------------------------------------------------------------------------
    const getListMenu = async () => {
      loadingScreen("Tunggu Sebentar, Sedang Login.").show();

      const { getMenu } = await servicesV1Auth();

      try {
        const hit = await getMenu();
        const resData = hit.response;
        // console.log("resData ", resData);

        $ck.setCookie("list-menu", JSON.stringify(resData));

        goToPageRole();
      } catch (e: any) {
        const err = e.response;
        console.log("err response ", err);

        loadingScreen().hide();
        gpf.handleEds(e);
      }
    };

    const goToPageRole = () => {
      setTimeout(() => {
        loadingScreen().hide();
        router.push("/" + getRole());
      }, 2000);
    };
    // END --- get data menu --------------------------------------------------------------------------------

    const modalLupaPassword = () => {
      gpf.showModal("#modal-lupa-password");
    };

    onBeforeMount(() => {
      if ($ck.isCookieAvailable("login-data")) {
        router.push("/" + getRole());
      }
    });

    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("login");
    });

    return {
      router,
      modalLupaPassword,
      gpf,

      form,
      doLogin,
      isPasswordVisible,
    };
  },
});
